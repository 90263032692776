/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Bungee&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

form {
  padding: 6px;
}

h2 {
  padding-left: 10px;
}

h3 {
  padding-left: 10px;
}

h4 {
  padding-left: 10px;
}

h5 {
  padding-left: 10px;
}

// responsive container class because PrimeFlex doesn't provide one
.container {
  margin-left: var(--content-padding);
  margin-right: var(--content-padding);
}

@media only screen and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  .container {
    max-width: 740px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media only screen and (min-width: 992px) {
  .container {
    max-width: 980px;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

.coming-soon {
  display: grid;
  height: 6rem;
  place-items: center;
  background: var(--blue-100);
  border-radius: 1em;
  border: thin dashed var(--blue-600);
}

// hide the recaptcha badge that is automatically added by the recaptcha library
// instead we add the required notice to the page footer.
.grecaptcha-badge {
  visibility: hidden;
}
